var thumbArray = [];
var preview;

function getThumb(seconds) {
    let results = thumbArray.filter(f => f.startSeconds < seconds && f.endSeconds >= seconds);
    if (results.length > 0) {
        return results[0];
    }
}

function unloadVtt() {
    thumbArray = [];
}

function loadVtt(url) {
    unloadVtt();
    // downloads vtt and parses it into a thumb array
    fetch(url).then(res => {
        let urlSplit = url.split('/');
        var baseUrl = '';
        for (let i = 0; i < urlSplit.length - 1; i++) {
            baseUrl += urlSplit[i] + "/"
        }
        res.text().then(body => {
            var strArray = body.split("\n")
            var thumb = {}
            strArray.forEach(str => {
                if (str.indexOf("-->") > -1) {
                    thumb.start = str.split("-->")[0].trim();
                    thumb.end = str.split("-->")[1].trim();
                    let timeStart = thumb.start.split(":");
                    let timeEnd = thumb.end.split(":");
                    thumb.startSeconds = (+timeStart[0]) * 60 * 60 + (+timeStart[1]) * 60 + (+timeStart[2]);
                    thumb.endSeconds = (+timeEnd[0]) * 60 * 60 + (+timeEnd[1]) * 60 + (+timeEnd[2]);
                }
                if (str.indexOf("xywh=") > -1) {
                    thumb.url = baseUrl + str.substr(0, str.indexOf("#"));
                    thumb.position = str.substr(str.indexOf("=") + 1);
                    var position = thumb.position.split(",");
                    thumb.x = +position[0];
                    thumb.y = +position[1];
                    thumb.w = +position[2];
                    thumb.h = +position[3];
                    thumbArray.push(thumb);
                    thumb = {};
                }
            })
        })
    })
}

async function initSeekPreview() {
    var slider = document.getElementsByClassName("shaka-seek-bar")[0];

    slider.addEventListener('mouseenter', function (event) {
        document.getElementById("seek-preview").style.display = "block";
    })

    slider.addEventListener('mouseleave', function (event) {
        document.getElementById("seek-preview").style.display = "none";
    })

    slider.addEventListener('mousemove', function (event) {
        if (thumbArray.length > 0) {
            var sliderWidth = slider.offsetWidth - 1;
            var sliderOffsetX = slider.getBoundingClientRect().left - document.documentElement.getBoundingClientRect().left;
            var currentMouseXPos = (event.clientX + window.pageXOffset) - sliderOffsetX;
            var sliderValAtPos = Math.round(currentMouseXPos / sliderWidth * slider.max + 1);

            if (sliderValAtPos < 0) sliderValAtPos = 0;
            if (sliderValAtPos > slider.max) sliderValAtPos = slider.max;

            let thumb = getThumb(sliderValAtPos);
            if (thumb) {
                let preview = document.getElementById("seek-preview");
                preview.style.backgroundPosition = (thumb.x * -1) + "px " + (thumb.y * -1) + "px";
                preview.style.backgroundImage = "url(" + thumb.url + ")";

                let video = document.getElementById("video").getBoundingClientRect();

                let xPosition = event.clientX - video.x - 160;
                let maxX = video.width - 10 - 320

                if (xPosition < 10) {
                    xPosition = 10;
                }
                else if (xPosition > maxX) {
                    xPosition = maxX;
                }

                preview.style.left = xPosition + 'px';

                var height = document.getElementById("video").getBoundingClientRect().height;
                var sliderOffsetY = height - 180 - 70;
                preview.style.top = sliderOffsetY + 'px';
            }
        }
    });
}