function startStripeSubscription(sessionId) {
    var stripe = Stripe('pk_live_h2nw329hi9fH3KFIRJN6saBC00zvtDbkvv');

    stripe.redirectToCheckout({
        sessionId: sessionId
    }).then(function (result) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
    });
}

function startStripeCardUpdate(sessionId) {
    var stripe = Stripe('pk_live_h2nw329hi9fH3KFIRJN6saBC00zvtDbkvv');

    stripe.redirectToCheckout({
        sessionId: sessionId
    }).then(function (result) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
    });
}